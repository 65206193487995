<template>
  <sign-page
    title-text="员工信息完善度"
    :request="request"
    :column-list="columnList"
    table-size="large"
    :title-menus="[]"
    :table-actions="[]"
    :table-actions-fixed="true"
    :need-data-file="true">
    <div slot="title" class="ir-t">
      <progress-dom :value="allRate" label="信息完善度"></progress-dom>
    </div>
  </sign-page>
</template>

<script>
import progressDom from '@/views/person/info/progress'

import {
  workerRequest
} from '@/api'

export default {
  components: {progressDom},
  computed: {
    columnList: {
      get () {
        let data = [{
          title: '排名',
          field: 'sortNum',
          dataType: Number,
          sort: true,
          fixed: 'left',
          filterRange: true
        },
        {
          title: '姓名',
          field: 'name',
          sort: true,
          width: 120,
          fixed: 'left'
        },
        {
          title: '工号',
          field: 'code',
          sort: true,
          width: 120,
          fixed: 'left'
        },
        {
          title: '信息总完善度',
          field: 'totalRate',
          sort: true,
          dataType: Number,
          fixed: 'left',
          render: (h, rowData) => {
            return h('div', rowData.totalRate + '%')
          },
          filterRange: true
        },
        {
          title: '基础信息',
          field: 'base_rate',
          sort: true,
          dataType: Number,
          render: (h, rowData) => {
            return h('div', rowData.base_rate + '%')
          },
          filterRange: true
        },
        {
          title: '工作信息',
          field: 'work_rate',
          sort: true,
          dataType: Number,
          render: (h, rowData) => {
            return h('div', rowData.work_rate + '%')
          },
          filterRange: true
        },
        {
          title: '教育经历',
          field: 'workerEduDatas_rate',
          sort: true,
          dataType: Number,
          render: (h, rowData) => {
            return h('div', rowData.workerEduDatas_rate + '%')
          },
          filterRange: true
        },
        {
          title: '工作经历',
          field: 'hisWorkDatas_rate',
          sort: true,
          dataType: Number,
          render: (h, rowData) => {
            return h('div', rowData.hisWorkDatas_rate + '%')
          },
          filterRange: true
        },
        {
          title: '职称聘任记录',
          field: 'workerJobTitleDatas_rate',
          sort: true,
          dataType: Number,
          render: (h, rowData) => {
            return h('div', rowData.workerJobTitleDatas_rate + '%')
          },
          filterRange: true
        },
        {
          title: '专业技术资格证书',
          field: 'workerCertificateProQuaDatas_rate',
          sort: true,
          dataType: Number,
          render: (h, rowData) => {
            return h('div', rowData.workerCertificateProQuaDatas_rate + '%')
          },
          filterRange: true
        },
        {
          title: '执业证书',
          field: 'workerCertificateDoctorPraDatas_rate',
          sort: true,
          dataType: Number,
          render: (h, rowData) => {
            return h('div', rowData.workerCertificateDoctorPraDatas_rate + '%')
          },
          filterRange: true
        },
        {
          title: '职业资格证书',
          field: 'workerCertificateDoctorQuaDatas_rate',
          sort: true,
          dataType: Number,
          render: (h, rowData) => {
            return h('div', rowData.workerCertificateDoctorQuaDatas_rate + '%')
          },
          filterRange: true
        },
        {
          title: '专业技术职务聘任证书',
          field: 'workerCertificateProHireDatas_rate',
          sort: true,
          dataType: Number,
          render: (h, rowData) => {
            return h('div', rowData.workerCertificateProHireDatas_rate + '%')
          },
          filterRange: true
        }]
        return data
      }
    }
  },
  methods: {
    getRate (keys, data) {
      const num = data.reduce((a, item) => {
        return a + keys.reduce((c, key) => {
          if (typeof key === 'string' && ((Array.isArray(item[key]) && item[key].length) || (!Array.isArray(item[key]) && item[key]))) {
            return c + 1
          } else {
            return c
          }
        }, 0)
      }, 0)
      let rate = (num / (data.length * keys.length) || 0) * 100
      return Number(rate.toFixed(0))
    },
    async loadData () {
      this.allRate = 0
      let datas = await workerRequest.getDetail()
      let keysMap = {
        workerEduDatas: ['edu', 'pro', 'school', 'startDate', 'endDate', 'xz', 'zsCode', 'jyxs', 'type'],
        hisWorkDatas: ['orgName', 'jobTitle', 'startTime', 'endTime'],
        workerJobTitleDatas: ['type', 'jobTitleId', 'startDate', 'endDate', 'hireStartDate', 'hireEndDate', 'hisName'],
        workerCertificateDoctorPraDatas: ['code','mType', 'issueOrg', 'issueUser', 'getDate', 'practiceAddress', 'practiceType', 'practiceRange', 'status'],
        workerCertificateDoctorQuaDatas: ['code', 'issueOrg', 'issueUser', 'getDate', 'gradSchool', 'pro', 'mType', 'status'],
        workerCertificateProHireDatas: ['code', 'hirePro', 'hireOrg', 'hireWorker', 'hireStartDate', 'hireEndDate', 'status'],
        workerCertificateProQuaDatas: ['code', 'getDate', 'xlmc', 'pro', 'certificateId', 'workerOrg', 'status']
      }
      let baseKeys = ['name', 'sex', 'clan', 'src', 'politicalStatus', 'idNo', 'birth', 'birth', 'sf', 'education', 'hometown', 'phone', 'agreementCode', 'graduatedSchool']
      let workKeys = ['code', 'orgName', 'positionId', 'positionList', 'type',  'jobTypeC', 'correctionDate', 'entryDate', 'jobType', 'shuangjiantiao', 'email', 'jobTitle', 'employmentForm', 'discipline']
      datas.forEach((v) => {
        v.name = v.worker.name
        v.code = v.worker.code
        Object.keys(keysMap).forEach((key) => {
          v[key + '_rate'] = this.getRate(keysMap[key], v[key])
        })
        v.base_rate = baseKeys.filter(key => v[key] !== undefined && v[key] !== null && v[key] !== '').length / baseKeys.length * 100
        v.work_rate = workKeys.filter(key => v[key] !== undefined && v[key] !== null && v[key] !== '').length / workKeys.length * 100
        v.base_rate = Number(v.base_rate.toFixed(0))
        v.work_rate = Number(v.work_rate.toFixed(0))
        v.totalRate = 0
        Object.keys(v).forEach((key) => {
          if (key.includes('_rate')) {
            v.totalRate += v[key]
          }
        })
        v.totalRate = Number((v.totalRate / 9).toFixed(0))
        this.allRate += v.totalRate
      })
      this.allRate = Number((this.allRate / datas.length).toFixed(0))
      datas.sort((a, b) => {
        return b.totalRate - a.totalRate
      })
      datas.forEach((v, index) => {
        v.sortNum = index + 1
      })
      return datas
    }
  },
  data () {
    return {
      request: {
        get: this.loadData
      },
      allRate: 0
    }
  }
}
</script>

<style lang="less" scoped>
.ir-t {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
}
</style>